<template>
  <div class="table-container" v-resize="onResize">
    <h4 class="pl-4 mt-2">On total, there are {{ this.files.length }} file(s).</h4>
    <v-data-table
        :headers="headers"
        :items="files"
        :items-per-page="10"
        class="elevation-1 document-quantity-table"
        fixed-header
        :height="windowSize.y - 348"
        @click:row="openDialogToViewFile($event)"
    >
      <template slot="item.id" slot-scope="props">
        {{ props.index + 1 }}
      </template>
    </v-data-table>
    <div class="hidden">
      <ViewConcordDocumentDialog :dialog="showDialog" :fileContent="dialogData" :filename="filename"
                                 :foldername="foldername"
                                 @closeDialog="closeViewFileDialog"></ViewConcordDocumentDialog>
      <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar()">
      </InfoSnackbar>
    </div>

  </div>
</template>

<script>
import * as _ from 'lodash-es';
import ViewConcordDocumentDialog from '../reuseable/view-concord-document-dialog';
import InfoSnackbar from '../reuseable/info-snackbar';

export default {
  name: "DocumentQuantity",
  components: {ViewConcordDocumentDialog, InfoSnackbar},
  props: {
    getFolders: {
      type: Object,
      require: true,
    },
    selectedFolder: {
      type: String,
      require: true,
    },
  },
  watch: {
    getFolders: {
      immediate: true,
      handler() {
        this.getFiles();
      }
    },
    selectedFolder: {
      handler() {
        this.getFiles();
      }
    }
  },
  data: function () {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      files: [],
      showDialog: false,
      dialogData: null,
      filename: null,
      foldername: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        {
          text: "File name",
          align: "start",
          value: "filename",
          sortable: false,
        },

      ],
      showSnackbar: false,
      snackbarText: '',
    };
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    getFiles() {
      if (!this.selectedFolder) {
        this.files = [];
        return;
      }
      this.files = [];
      let folders = _.cloneDeep(this.getFolders);

      if (this.selectedFolder !== 'All') {
        this.files = folders[this.selectedFolder].map((file) =>
            ({filename: file.filename, foldername: this.selectedFolder}));
      } else {
        for (const [folder, files] of Object.entries(folders)) {
          files.forEach((file) => {
            this.files.push({
              foldername: folder,
              filename: file.filename
            })
          })
        }
      }

      let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
      this.files.sort(function(a, b) {
        return collator.compare(a.filename, b.filename)
      });
    },
    async openDialogToViewFile(item) {

      const payload = {
        folder: item.foldername,
        file: item.filename
      };

      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.get_content(payload);
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {
          const sortedData = res.data.sort(function (a, b) {
            return a.line - b.line || a.index - b.index;
          });

          const fileContent = sortedData.map(word => word.tok).join(' ');

          this.dialogData = fileContent;
          this.filename = item.filename;
          this.foldername = item.foldername;
          this.showDialog = true;
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;
        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }
    },

    closeViewFileDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>

.hidden {
  display: none;
}
</style>
