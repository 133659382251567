<template>
  <div class="table-container" v-resize="onResize">
    <h4 class="pl-4 mt-2">On average, there are {{averageWordsPerSentence}} word(s) per sentence. On total, there are {{numberOfSentences}} sentence(s)</h4>
    <v-data-table
        :headers="headers"
        :items="sentencesToDisplay"
        :items-per-page="10"
        class="elevation-1"
        fixed-header
        :height="windowSize.y - 428"
        :search="search"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
        ></v-text-field>
      </template>
      <template slot="item.id" slot-scope="props">
        {{ props.index + 1 }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "SentenceQuantity",
  props: {
    sentences: {
      type: Array,
      required: true,
    },
    averageWordsPerSentence: {
      type: String,
      required: true,
    },
    numberOfSentences: {
      type: Number,
      required: true,
    },
  },
  watch: {
    sentences(newSentences) {
      this.sentencesToDisplay = newSentences;
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false
        },
        {
          text: "Words In Sentence",
          align: "start",
          value: "length",
          sortable: true
        },
        {
          text: "Count",
          align: "start",
          value: "count",
          sortable: true
        },
        {
          text: "Frequency (%)",
          align: "start",
          value: "frequency",
          sortable: false
        },
      ],
      sentencesToDisplay: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      search: ''
    };
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
  },
};
</script>

<style scoped>

</style>
