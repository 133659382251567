<template>
  <div class="table-container" v-resize="onResize">
    <v-data-table
        :headers="headers"
        :items="wordsToDisplay"
        :items-per-page="10"
        class="elevation-1"
        fixed-header
        :height="windowSize.y - 404"
        :custom-filter="$helper.CustomFilter.replaceSpaceWithDashFilter"
        :custom-sort="$helper.CustomSort.wordMultiPosSort"
        :search="search"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
        ></v-text-field>
      </template>
      <template slot="item.id" slot-scope="props">
        {{ props.index + 1 }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "WordMultiPosQuantity",
  computed: {

  },
  props: {
    word: {
      type: Array,
      required: true,
    },
  },
  watch: {
    word(newWords) {
      this.wordsToDisplay = newWords;
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false
        },
        {
          text: "Word",
          align: "start",
          value: "normalized",
          sortable: false
        },
        {
          text: "PoS",
          align: "start",
          value: "poses",
          sortable: true
        },
        {
          text: "Word Count",
          align: "start",
          value: "count",
          sortable: true
        },
        {
          text: "Frequency (%)",
          align: "start",
          value: "frequency",
          sortable: false
        },
      ],
      wordsToDisplay: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      search: ''
    };
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
  },
};
</script>

<style scoped>

</style>
