<template>
  <div class="pa-4 h-100-p w-100-p">
    <div class="title mb-4">
      <h1>Statistics</h1>
    </div>
    <v-container class="pa-0 pl-4">
      <v-row>
        <v-col cols="3">
          <BaseSelect
              :modelValue="selectedCountBy"
              :options="statisticsOptions"
              @updateModel="updateCountBy"
          >
          </BaseSelect>
        </v-col>
        <v-col cols="3">
          <BaseSelect
              :modelValue="selectedCountFileBy"
              :options="statisticsFilesOptions"
              @updateModel="updateCountFileBy"
              label="Display Folder By"
          >
          </BaseSelect>
        </v-col>
        <v-col cols="3">
          <v-btn class="mr-3"
                 color="primary"
                 @click="applyStatisticCount"
                 :disabled="getApiPending"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="statistics-tabs pl-4">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tabs>
        <v-tab>Word quantity</v-tab>
        <v-tab>PoS quantity</v-tab>
        <v-tab>Multi PoS quantity</v-tab>
        <v-tab>Entity quantity</v-tab>
        <v-tab>Sentence quantity</v-tab>
        <v-tab>Document quantity</v-tab>
        <v-tab-item :eager="true">
          <WordQuantity :word="word"></WordQuantity>
        </v-tab-item>
        <v-tab-item :eager="true">
          <PosQuantity :pos="pos"></PosQuantity>
        </v-tab-item>
        <v-tab-item :eager="true">
          <WordMultiPosQuantity :word="wordMultiPos"></WordMultiPosQuantity>
        </v-tab-item>
        <v-tab-item :eager="true">
          <EntityQuantity :entity="ner"></EntityQuantity>
        </v-tab-item>
        <v-tab-item :eager="true">
          <SentenceQuantity :sentences="lens" :average-words-per-sentence="averageWordsPerSentence" :number-of-sentences="numberOfSentence"></SentenceQuantity>
        </v-tab-item>
        <v-tab-item :eager="true">
          <DocumentQuantity :getFolders="getFolders" :selected-folder="selectedFolder"></DocumentQuantity>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import WordQuantity from "../components/statistics/word-quantity";
import PosQuantity from "../components/statistics/pos-quantity";
import WordMultiPosQuantity from "../components/statistics/word-multi-pos-quantity";
import EntityQuantity from "../components/statistics/entity-quantity";
import SentenceQuantity from "../components/statistics/sentence-quantity";
import DocumentQuantity from "../components/statistics/document-quantity";
import BaseSelect from "../components/reuseable/count-by-select";

export default {
  components: {
    BaseSelect,
    SentenceQuantity,
    EntityQuantity,
    PosQuantity,
    WordQuantity,
    DocumentQuantity,
    WordMultiPosQuantity,
  },
  computed: {
    ...mapGetters([
      "getFolders",
      "getDisplayStatisticsBy",
      "getDisplayStatisticsByFile",
      "getApiPending"
    ]),
  },
  watch: {
    getFolders: {
      immediate: true,
      handler(folders) {
        this.folders = Object.keys(folders).sort();
        this.statisticsOptions = ["All"].concat(this.folders);
      }
    }
  },
  data: function () {
    return {
      selectedCountBy: "All",
      selectedCountFileBy: "All",
      statisticsOptions: ["All"],
      statisticsFilesOptions: ["All"],
      folders: [],
      lens: [],
      ner: [],
      pos: [],
      word: [],
      wordMultiPos: [],
      averageWordsPerSentence: '0',
      numberOfSentence: 0,
      selectedFolder: null
    };
  },
  created() {
    this.updateCountBy(this.getDisplayStatisticsBy);
    this.updateCountFileBy(this.getDisplayStatisticsByFile);
  },
  methods: {
    updateCountBy(value) {
      this.selectedCountBy = value;

      if (value === 'All') {
        this.selectedCountFileBy = 'All';
        this.statisticsFilesOptions = ['All'];
      } else {
        this.selectedCountFileBy = 'All';
        this.statisticsFilesOptions = ["All"];
        if (this.getFolders[value]) {
          this.statisticsFilesOptions = this.statisticsFilesOptions.concat(
              this.getFolders[value].map(file => file.filename));

          let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
          this.statisticsFilesOptions.sort(collator.compare);
        }
      }
    },
    updateCountFileBy(value) {
      this.selectedCountFileBy = value;
    },
    applyStatisticCount() {
      let query = {
        corpus: false,
        field: false,
        text: false,
        folder: null,
        file: null
      };

      if (this.selectedCountBy === 'All') {
        query.corpus = true;
      } else {

        if (this.selectedCountFileBy === 'All') {
          query.field = true;
          query.folder = this.selectedCountBy;
        } else {
          query.text = true;
          query.file = this.selectedCountFileBy;
          query.folder = this.selectedCountBy;
        }

      }

      this.getStatistics(query);
    },

    async getStatistics(query) {
      this.$store.dispatch('updateDisplayStatisticsBy', this.selectedCountBy);
      this.$store.dispatch('updateDisplayStatisticsByFile', this.selectedCountFileBy);
      this.selectedFolder = this.selectedCountBy;

      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.statistics({query});
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {

          const wordCounts = res.data.word.reduce((acc, cur) => acc + cur.count, 0);
          const sentenceWordCounts = res.data.lens.reduce((acc, cur) => acc + cur.length * cur.count, 0);

          if (wordCounts !== sentenceWordCounts) {
            console.error('Something went wrong when uploading files, database is out of sync');
          }

          this.word = res.data.word.filter(word => word.poses !== 'PU').map((aword) => ({
            ...aword,
            frequency: -Math.log10(aword.count / wordCounts).toFixed(4)
          }));

          this.wordMultiPos = this.word.filter((word) => {
            return word.poses.split(',').length > 1;
          });

          this.pos = res.data.pos.map((apos) => ({
            ...apos,
            frequency: -Math.log10(apos.count / wordCounts).toFixed(4)
          }));

          this.ner = res.data.ner.map((aner) => ({
            ...aner,
            frequency: -Math.log10(aner.count / wordCounts).toFixed(4)
          }));

          this.numberOfSentence = res.data.lens.reduce((acc, cur) => acc + cur.count, 0);
          let averageWordsPerSentence = 0;
          if (this.numberOfSentence > 0) {
            this.lens = res.data.lens.map((len) => ({
              ...len,
              frequency: -Math.log10(len.count / this.numberOfSentence).toFixed(4)
            }));
            averageWordsPerSentence = (wordCounts / this.numberOfSentence).toFixed(2);
          }

          if (averageWordsPerSentence && !isNaN(Number(averageWordsPerSentence))) {
            this.averageWordsPerSentence = averageWordsPerSentence;
          } else {
            this.averageWordsPerSentence = '0';
          }

        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;
        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }

    }
  },
};
</script>

<style scoped></style>
